import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  phoneNo: "",
  companyName: "",
};
export const Header = (props) => {
  const [formValues, setFormValues] = useState(initialState);
  const [clearForm, setClearForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const clearState = () => {
    setFormValues(initialState);
    setClearForm(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("email-sent");

    emailjs
      .sendForm(
        "service_kfwc8cn",
        "template_dvzqevg",
        e.target,
        "io_SO29d_SNnkzB3u"
      )
      .then(
        (result) => {
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    if (clearForm) {
      setClearForm(false);
    }
  }, [clearForm]);
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-md-offset-1 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                {/* <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Book free consultation now
                </a>{" "} */}
              </div>
              <div className="col-md-6 intro-form">
                <div className="section-title-header">
                  <h2>Get In Touch Now</h2>
                  {/* <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p> */}
                </div>
                <form name="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={formValues.name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={formValues.email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        className="form-control"
                        placeholder="Company Name"
                        required
                        onChange={handleChange}
                        value={formValues.companyName}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="tel"
                        pattern="[0-9]+"
                        id="phoneNo"
                        name="phoneNo"
                        className="form-control"
                        placeholder="Phone No"
                        required
                        onChange={handleChange}
                        value={formValues.phoneNo}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>

                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Book Free Consultation Call Today
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
